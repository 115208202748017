import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Mobile Support" />
    <h1>Mobile Support</h1>
    <p>For mobile app support, please email <a href="mailto:appissues@scopesfs.com">appissues@scopesfs.com</a>.</p>

    <p>You can also view our <a href="/privacy_policy">privacy policy</a>.</p>
  </Layout>
)

export default IndexPage
